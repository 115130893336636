import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FeatureGroup, GeoJSON, MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { makeStyles } from '@mui/styles';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';

import ClearIcon from '@mui/icons-material/Clear';

import AddressFilter from '../components/AddressFilter';
import CardsFilters from '../components/CardsFilters';
import CardsList from '../components/CardsList';
import utils from '../components/utils';

import { useEntitiesByFilter, useSchoolCount, useSrclists } from '../DataProvider';
import { useMarkerPins, useTNBoundary } from '../MapUtilsProvider';
import { StylesContext } from '../App';

const MAX_ENTITIES_IN_MAP = 1000;
const DEFAULT_MILES_SEARCH = 10;

const DEFAULT_BOUNDS = [35.860119, -86.660156];
const DEFAULT_ZOOM = 7.2;

const useStyles = makeStyles((theme) => ({
    searchSection: {
        backgroundColor: theme.palette.primary.dark,
        backgroundImage: "url('./images/home-header-index.png')",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        color: 'white',
        padding: '2rem',
        borderRadius: '.25rem',
    },

    nameFilter: {
        marginLeft: 'auto',
        borderRadius: '.25rem',
        border: '1px solid ' + theme.palette.primary.main,
        width: '15rem',
    },
}));

export default function Entities({ viewType }) {
    const myClasses = useStyles();
    const location = useLocation();
    const theme = useTheme();
    const reportClasses = useContext(StylesContext);

    const srclists = useSrclists();
    const schoolCount = useSchoolCount();

    const [selectedEntity, setSelectedEntity] = useState(null);
    const [homeAddress, setHomeAddress] = useState(location.state);
    const [milesSearch, setMilesSearch] = useState(DEFAULT_MILES_SEARCH);
    const [filters, setFilters] = useState({});
    const [nameFilter, setNameFilter] = useState('');
    const [sortBy, setSortBy] = useState(viewType === 'schools' ? (!!homeAddress ? 'distance' : 'name') : 'name');
    const [sortAsc, setSortAsc] = useState(true);
    const [currPage, setCurrPage] = useState(1);

    const { entities, numPerPage, firstRow, numPages } = useEntitiesByFilter({
        type: viewType,
        filters: filters,
        homeLocation: homeAddress,
        radius: milesSearch,
        name: nameFilter,
        page: currPage,
        sort: sortAsc ? 'asc' : 'desc',
        by: sortBy,
    });

    utils.setHomeTitle('Tennessee Department of Education');

    const pins = useMarkerPins();
    const TNBoundary = useTNBoundary();

    const mapRef = useRef();
    const mapGroupRef = useRef();

    // leave in #useEffect since the map has to be drawn before bounds are calculated
    useEffect(() => {
        const map = mapRef.current;
        if (!!map && entities.length > 0 && entities.length <= MAX_ENTITIES_IN_MAP) {
            const mapGroup = mapGroupRef.current;
            // account for group where no entities have lat/long values
            if (Object.keys(mapGroup.getBounds()).length > 0) {
                map.fitBounds(mapGroup.getBounds());
            } else {
                map.setView(DEFAULT_BOUNDS, DEFAULT_ZOOM);
            }
        } else {
            !!map && map.setView(DEFAULT_BOUNDS, DEFAULT_ZOOM);
        }
    }, [entities]);

    function zoomMap(entity) {
        const map = mapRef.current;
        !!map && map.setView([entity.latitude, entity.longitude]);

        setSelectedEntity(entity);
    }

    function handleAddressChange(newAddr) {
        setSelectedEntity(null);
        setHomeAddress(newAddr);
        setCurrPage(1);

        setSortBy(!newAddr ? 'name' : 'distance');
        setSortAsc(true);
    }
    function handleRadiusChange(newRadius) {
        setMilesSearch(newRadius);
        setSortBy('distance');
        setSortAsc(true);
        setCurrPage(1);
    }

    function handleFiltersChange(newFilters) {
        setSelectedEntity(null);
        setFilters(newFilters);
        setCurrPage(1);
    }

    function onSortChange(property) {
        const isAsc = sortBy !== property || !sortAsc; // reset to 'asc' if a new column, otherwise toggle

        setSortBy(property);
        setSortAsc(isAsc);
    }

    const rowsToShow = entities.slice(firstRow, firstRow + numPerPage);

    // this is here since it can't be within any conditional logic;
    const mediaMDUp = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <>
            {viewType === 'schools' ? (
                <div className={myClasses.searchSection}>
                    <Grid container spacing={2}>
                        {mediaMDUp ? <Grid item sm={1} /> : null}
                        <Grid item sm={mediaMDUp ? 11 : 12}>
                            <p style={{ fontWeight: 'bold', fontSize: '2rem', margin: 0 }}>Find A School For Your Child</p>
                        </Grid>

                        <Grid item xs={8} md={6} style={{ margin: 'auto' }}>
                            <p style={{ textAlign: 'center' }}>Enter Your Address</p>
                            <AddressFilter value={homeAddress} placeholder='Enter Your Address' onChange={handleAddressChange} />
                        </Grid>
                        <Grid item xs={4} md={3} style={{ margin: 'auto' }}>
                            <p style={{ marginLeft: '.5rem' }}>Within</p>
                            <Select
                                value={milesSearch}
                                onChange={(evt) => {
                                    handleRadiusChange(evt.target.value);
                                }}
                                disabled={!homeAddress}
                                style={{ backgroundColor: 'white', height: '2.5rem' }}
                            >
                                {srclists.distances.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                </div>
            ) : (
                <div className={myClasses.searchSection}>
                    <Grid container spacing={2}>
                        <>
                            {mediaMDUp ? <Grid item sm={1} /> : null}
                            <Grid item sm={mediaMDUp ? 11 : 12}>
                                <p style={{ fontWeight: 'bold', fontSize: '2rem', margin: 0 }}>Find A School District</p>
                            </Grid>
                        </>
                    </Grid>
                </div>
            )}

            <Grid container item direction='row-reverse' style={{ margin: '1rem 0' }}>
                <Grid item xs={12} lg={8}>
                    <MapContainer scrollWheelZoom={false} style={{ width: '100%', height: '30rem' }} ref={mapRef}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        />
                        <GeoJSON data={TNBoundary} className={reportClasses.mapBorder} />
                        <FeatureGroup ref={mapGroupRef}>
                            {entities.length <= MAX_ENTITIES_IN_MAP
                                ? entities
                                      .filter((_entity) => !!_entity.latitude && !!_entity.longitude && _entity.id !== selectedEntity?.id)
                                      .map((_entity) => {
                                          const isShown = rowsToShow.some((rts) => rts.id === _entity.id);
                                          // predefining props here since the 'icon' inclusion is conditional
                                          const markerProps = {
                                              key: _entity.id,
                                              position: [_entity.latitude, _entity.longitude],
                                              eventHandlers: {
                                                  click: () => {
                                                      window.open(
                                                          import.meta.env.VITE_BASEPATH + (viewType === 'schools' ? '/school/' : '/district/') + _entity.id,
                                                          '_blank'
                                                      );
                                                  },
                                              },
                                              icon: isShown ? pins.secondary : pins.main,
                                              zIndexOffset: isShown ? 500 : 0,
                                          };

                                          return (
                                              <Marker {...markerProps}>
                                                  <Tooltip>
                                                      <div style={{ textAlign: 'left' }}>
                                                          <b>{_entity.name}</b>
                                                          <br />
                                                          {_entity.address}
                                                      </div>
                                                  </Tooltip>
                                              </Marker>
                                          );
                                      })
                                : null}
                            {!!selectedEntity ? (
                                <Marker
                                    key={selectedEntity.id}
                                    position={[selectedEntity.latitude, selectedEntity.longitude]}
                                    eventHandlers={{
                                        click: (evt) => {
                                            window.open(
                                                import.meta.env.VITE_BASEPATH + (viewType === 'schools' ? '/school/' : '/district/') + selectedEntity.id,
                                                '_blank'
                                            );
                                        },
                                    }}
                                    icon={pins.tertiary}
                                    zIndexOffset={1000}
                                >
                                    <Tooltip>
                                        <div style={{ textAlign: 'left' }}>
                                            <b>{selectedEntity.name}</b>
                                            <br />
                                            {selectedEntity.address}
                                        </div>
                                    </Tooltip>
                                </Marker>
                            ) : null}
                        </FeatureGroup>
                    </MapContainer>
                    {viewType === 'schools' && entities.length > MAX_ENTITIES_IN_MAP && entities.length < schoolCount ? (
                        <h3>Too many schools to show in map. Please refine your search to see locations.</h3>
                    ) : null}
                </Grid>

                <Grid item xs={12} lg={4}>
                    <CardsFilters filters={filters} viewType={viewType} onFilterChange={handleFiltersChange} />
                </Grid>
            </Grid>

            <hr />

            <div style={{ display: 'flex', marginBottom: '.75rem' }}>
                {numPages >= 2 ? (
                    <Pagination
                        count={numPages}
                        page={currPage}
                        shape='rounded'
                        showFirstButton
                        showLastButton
                        onChange={(e, newpage) => {
                            setCurrPage(newpage);
                        }}
                    />
                ) : null}
                <Input
                    value={nameFilter}
                    onChange={(evt) => {
                        setNameFilter(evt.target.value);
                        setCurrPage(1);
                    }}
                    placeholder={'Filter Results by ' + (viewType === 'schools' ? 'School' : 'District') + ' Name'}
                    endAdornment={
                        !!nameFilter ? (
                            <InputAdornment position='end'>
                                <Button
                                    onClick={() => {
                                        setNameFilter('');
                                        setCurrPage(1);
                                    }}
                                    aria-label='clear name filter'
                                >
                                    <ClearIcon />
                                </Button>
                            </InputAdornment>
                        ) : null
                    }
                    className={myClasses.nameFilter}
                />
                <br />
            </div>
            <CardsList
                key='cardslist'
                entities={rowsToShow}
                viewType={viewType}
                includeDistance={!!homeAddress}
                onZoomMap={zoomMap}
                sortBy={sortBy}
                sortDirection={sortAsc ? 'asc' : 'desc'}
                onChangeSort={onSortChange}
            />
        </>
    );
}
